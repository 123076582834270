<template>
  <AppButton
    as-child
    class="flex h-full items-center justify-center gap-2 px-10"
    variant="secondary"
  >
    <NuxtLink to="#contact">
      <Icon name="carbon:send-alt" size="1.5em" />

      <span class="text-sm font-bold uppercase tracking-widest">Napište nám</span>
    </NuxtLink>
  </AppButton>
</template>
